import BasePlugin from '../BasePlugin'

export default class VATAmountCalculationInIncomeAndExpenseBudget extends BasePlugin {
  async execute () {
    let payload = {
      'recordId': this.context.getModel().id,
      'registryId': 197
    }
    this.send(payload)
  }
}
